export default (api) => ({
  async getProfile() {
    const response = await api.get("/auth/me");
    return response.data;
  },

  async updateUser(data) {
    const response = await api.patch("/user", { ...data, is_active: true });
    return response.data;
  },

  async updateUserSportSkill(userId, sportData) {
    const response = await api.post(`/user/sport_skill/${userId}`, sportData);
    return response.data;
  },

  async initiateOtpLogin(data) {
    const response = await api.post("/auth/otp_login_signup", data);
    return response.data;
  },

  async verifyOtpToken(code, phoneNumber) {
    const response = await api.post("/auth/otp_token", {
      code,
      phone_number: phoneNumber,
    });
    return response.data;
  },

  async getUserCarnets() {
    const response = await api.get(`/user_venue_carnet`);
    return response.data;
  },

  async getUserReservations(venueId, startTime, endTime) {
    let url = `/reservation/${venueId}`;
    const params = {};
    if (startTime) params.start_time = startTime;
    if (endTime) params.end_time = endTime;
    const response = await api.get(url, { params });
    return response.data;
  },

  async deleteUserReservation(reservationId) {
    const response = await api.delete(`/reservation/${reservationId}`);
    return response.data;
  },
});

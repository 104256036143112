<template>
  <nav class="bg-dynamic-orange p-4">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo and main navigation links -->
      <div class="flex items-center space-x-4">
        <img src="@/assets/logo.png" alt="Logo" class="h-8 w-auto" />
        <router-link
          to="/"
          class="text-off-white hover:text-light-grey text-body-medium"
          >Strona główna</router-link
        >
        <div v-if="isAuthenticated" class="relative" ref="accountMenuContainer">
          <button
            @click="toggleMenu"
            class="text-off-white hover:text-light-grey text-body-medium flex items-center"
          >
            Twoje Konto
            <svg
              class="w-4 h-4 ml-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>
          <div
            v-show="showMenu"
            class="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
          >
            <router-link
              @click="closeMenu"
              to="/your-account"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Profil</router-link
            >
            <router-link
              @click="closeMenu"
              to="/your-sports"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Poziomy gry</router-link
            >
            <router-link
              @click="closeMenu"
              to="/my-carnets"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Karnety</router-link
            >
            <router-link
              @click="closeMenu"
              to="/my-reservations"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >Rezerwacje</router-link
            >
          </div>
        </div>
      </div>

      <!-- Authentication-related links and user avatar -->
      <div class="flex items-center space-x-4">
        <template v-if="!isAuthenticated">
          <router-link
            to="/login"
            class="text-off-white hover:text-light-grey text-body-medium"
            >Marketplace/Mobile Login</router-link
          >
        </template>
        <template v-else>
          <button
            @click="handleLogout"
            class="text-off-white hover:text-light-grey text-body-medium"
          >
            Wyloguj
          </button>
          <div
            class="w-8 h-8 rounded-full bg-off-white flex items-center justify-center text-dynamic-orange font-bold"
          >
            {{ userInitials }}
          </div>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",

  setup() {
    const showMenu = ref(false);
    const accountMenuContainer = ref(null);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const closeMenu = () => {
      showMenu.value = false;
    };

    const handleClickOutside = (event) => {
      if (
        accountMenuContainer.value &&
        !accountMenuContainer.value.contains(event.target)
      ) {
        closeMenu();
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      showMenu,
      accountMenuContainer,
      toggleMenu,
      closeMenu,
    };
  },

  computed: {
    ...mapGetters("user", ["isAuthenticated", "currentUser"]),
    userInitials() {
      const { first_name, last_name } = this.currentUser;
      if (first_name && last_name) {
        return `${first_name[0]}${last_name[0]}`.toUpperCase();
      } else if (first_name) {
        return first_name[0].toUpperCase();
      } else if (last_name) {
        return last_name[0].toUpperCase();
      }
      return "";
    },
  },

  methods: {
    ...mapActions("user", ["logout"]),

    async handleLogout() {
      console.log("handleLogout called");
      await this.logout();
      console.log("Logout completed, redirecting to login page");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-50 {
  z-index: 50;
}
</style>

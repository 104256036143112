export default (api) => ({
  async getVenues() {
    const response = await api.get("/venue");
    return response.data;
  },

  async getSingleVenue(venueID) {
    const response = await api.get(`/venue/${venueID}`);
    return response.data;
  },

  async getAppSports() {
    const response = await api.get("/app/sport_type");
    return response.data;
  },

  async getUserDataFromMagicToken(magicToken) {
    const response = await api.post("/user/magic_token", {
      magic_token: magicToken,
    });
    return response.data;
  },

  async shortURL(token, userid) {
    const response = await api.post("/app/url_shortener", {
      user_id: userid,
      short_url_token: token,
    });
    return response.data;
  },

  async sendEmailMagicLink(phoneNumber) {
    const response = await api.post("/app/email_magiclink", {
      phone_number: phoneNumber,
    });
    return response.data;
  },
});

export default (api) => ({
  async tmp_check_availability(data) {
    const response = await api.get("/tmp_check_availability", { params: data });
    return response.data;
  },

  async tmp_get_object_availabilities(data) {
    const response = await api.get("/tmp_get_object_availabilities", {
      params: data,
    });
    return response.data;
  },

  async getCarnetsFromVenue(venueId) {
    const response = await api.get(`/venue/carnet_type/${venueId}`);
    return response.data;
  },
});

import api from "@/api";

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {},
    authToken: localStorage.getItem("authToken") || null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setAuthToken(state, token) {
      state.authToken = token;
      localStorage.setItem("authToken", token);
    },
    clearAuth(state) {
      state.user = {};
      state.authToken = null;
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
    },
  },
  actions: {
    async initiateOtpLogin(_, phoneNumber) {
      const response = await api.users.initiateOtpLogin({
        source: "web",
        phone_number: phoneNumber,
      });
      console.log("initiateOtpLogin response:", response);
      return response;
    },
    async verifyOtpToken(_, { code, phone_number }) {
      return await api.users.verifyOtpToken(code, phone_number);
    },
    setAuthToken({ commit }, token) {
      commit("setAuthToken", token);
    },
    setCurrentUser({ commit }, user) {
      commit("setUser", user);
      if (user.authToken) {
        commit("setAuthToken", user.authToken);
      }
    },
    async fetchUserProfile({ commit }) {
      const response = await api.users.getProfile();
      if (!response || !response.id) {
        console.error("Error: User profile response is missing ID", response);
        throw new Error("User profile response is missing ID");
      }
      commit("setUser", response);
      return response;
    },
    async updateUserProfile({ commit }, userData) {
      const response = await api.users.updateUser(userData);
      commit("setUser", response);
      return response;
    },
    logout({ commit }) {
      commit("clearAuth");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    currentUser: (state) => state.user,
    userDetails: (state) => ({
      first_name: state.user.first_name || "",
      last_name: state.user.last_name || "",
      email: state.user.email || "",
    }),
  },
};

import { createRouter, createWebHistory } from "vue-router";
import { generateRoutes } from "./auto-import";

const routeConfigs = [
  {
    path: "/",
    name: "Home",
    component: "home/Home",
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: "users/Login",
  },
  {
    path: "/login-magic-link",
    name: "LoginMagicLink",
    component: "users/LoginMagicLink",
  },
  {
    path: "/complete-registration",
    name: "CompleteRegistration",
    component: "users/CompleteRegistration",
    meta: { requiresAuth: true },
  },
  {
    path: "/your-account",
    name: "YourAccount",
    component: "users/Account",
    meta: { requiresAuth: true },
  },
  {
    path: "/your-sports",
    name: "YourSports",
    component: "users/YourSports",
    meta: { requiresAuth: true },
  },
  {
    path: "/dump-account",
    name: "DumpAccount",
    component: "users/DumpAccount",
    meta: { requiresAuth: true },
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: "users/ConfirmEmail",
    meta: { requiresAuth: false },
  },
  {
    path: "/reservation-debug/:venueId",
    name: "ReservationDebug",
    component: "reservation-debug/reservation-debug",
    meta: { requiresAuth: false },
  },
  {
    path: "/reservation/:venueId",
    name: "Reservations",
    component: "reservations/reservations",
    meta: { requiresAuth: false },
  },
  {
    path: "/trainers/:venueId",
    name: "Trainers",
    component: "trainers/trainers",
    meta: { requiresAuth: false },
  },
  {
    path: "/carnets/:venueId",
    name: "Carnets",
    component: "carnets/carnets",
    meta: { requiresAuth: false },
  },
  {
    path: "/venue/:id",
    name: "VenueDetails",
    component: "venue/VenueDetails",
    meta: { requiresAuth: false },
  },
  {
    path: "/my-carnets",
    name: "UserCarnets",
    component: "users/carnets/UserCarnets",
    meta: { requiresAuth: true },
  },
  {
    path: "/my-reservations",
    name: "UserReservations",
    component: "users/reservations/UserReservations",
    meta: { requiresAuth: true },
  },
];

const routes = generateRoutes(routeConfigs);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <transition name="fade">
    <div
      v-if="show"
      :class="['fixed bottom-4 right-4 p-4 rounded-md shadow-md', typeClass]"
    >
      {{ message }}
    </div>
  </transition>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "GlobalMessage",
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "info",
      validator: (value) =>
        ["success", "error", "info", "warning"].includes(value),
    },
    duration: {
      type: Number,
      default: 3000,
    },
  },
  setup(props) {
    const show = ref(true);

    const typeClass = computed(() => {
      switch (props.type) {
        case "success":
          return "bg-green-500 text-white";
        case "error":
          return "bg-red-500 text-white";
        case "warning":
          return "bg-yellow-500 text-white";
        default:
          return "bg-blue-500 text-white";
      }
    });

    setTimeout(() => {
      show.value = false;
    }, props.duration);

    return {
      show,
      typeClass,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="min-h-screen bg-gray-100">
    <NavBar />
    <main class="w-full">
      <router-view></router-view>
    </main>
    <GlobalMessage v-if="show" :message="message" :type="type" />
  </div>
</template>

<script setup>
import { provide } from "vue";
import NavBar from "@/components/NavBar.vue";
import GlobalMessage from "@/components/GlobalMessage.vue";
import { useGlobalMessage } from "@/composables/useGlobalMessage";

const { message, type, show, showMessage } = useGlobalMessage();

// Udostępnij funkcję showMessage dla wszystkich komponentów potomnych
provide("showMessage", showMessage);
</script>

import { ref, readonly } from "vue";

const message = ref("");
const type = ref("info");
const show = ref(false);

export function useGlobalMessage() {
  const showMessage = (newMessage, newType = "info", duration = 3000) => {
    message.value = newMessage;
    type.value = newType;
    show.value = true;

    setTimeout(() => {
      show.value = false;
    }, duration);
  };

  const hideMessage = () => {
    show.value = false;
  };

  return {
    message: readonly(message),
    type: readonly(type),
    show: readonly(show),
    showMessage,
    hideMessage,
  };
}
